<template>
    <PageLayout>
        <template>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <div class="d-flex justify-space-between mb-6">
                    <h1>
                        {{ $t('analytics_dashboard') }}
                    </h1>
                </div>
                <v-form @submit.prevent="search">
                    <v-card class="mb-6">
                        <v-card-text>
                            <v-row v-if="filter_show">
                                <v-col cols="12" sm="6">
                                    <v-menu v-model="dateMenuFrom" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <ValidationProvider ref="dateFrom" rules="date_format" v-slot="{ errors, valid }">
                                                <v-text-field
                                                    v-model="dateFrom"
                                                    :error-messages="errors"
                                                    readonly v-on="on"
                                                    @click:clear="dateFrom = null"
                                                    :label="$t('date_from')"
                                                    outlined
                                                    solo
                                                    flat
                                                    :disabled="loading"
                                                    :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                    clearable
                                                    hide-details
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </template>
                                        <v-date-picker ref="picker" v-model="dateFrom" :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')" :locale="lang" @change="dateMenuFrom = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-menu v-model="dateMenuTo" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <ValidationProvider ref="dateTo" rules="date_format" v-slot="{ errors, valid }">
                                                <v-text-field
                                                    v-model="dateTo"
                                                    :error-messages="errors"
                                                    :label="$t('date_to')"
                                                    readonly v-on="on"
                                                    @click:clear="dateTo = null"
                                                    outlined
                                                    solo
                                                    flat
                                                    :disabled="loading"
                                                    :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                    clearable
                                                    hide-details
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </template>
                                        <v-date-picker ref="picker" v-model="dateTo" :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')" :max="$moment().format('YYYY-MM-DD')" :locale="lang"
                                                       @change="dateMenuTo = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-center justify-sm-end align-self-center">
                                    <v-btn type="submit" :disabled="loading" :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">{{ $t('search') }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
                <v-row v-if="false">
                    <v-col cols="12">
                        {{ tasks }}
                        <v-card class="d-flex flex-wrap" flat tile>
                            <v-card v-for="(task, i) in tasks" :key="i"
                                    :min-width="$vuetify.breakpoint.smAndDown ? '98%' : '49%'"
                                    :max-width="$vuetify.breakpoint.smAndDown ? '98%' : '49%'"
                                    class="pa-1 ma-1 mx-auto text-center" elevation="3" outlined tile>
                                <bar-chart :chartData="task" :options="optionsChartBar"></bar-chart>
                            </v-card>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-card class="d-flex flex-wrap" flat tile>
                            <template v-for="(task, i) in tasks">
                                <v-card
                                    min-width="100%"
                                    max-width="100%"
                                    outlined
                                    tile
                                >
                                    <pie-chart :chartData="task" :options="optionsChartBar"></pie-chart>
                                </v-card>
                            </template>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card class="d-flex flex-wrap" flat tile>
                            <template v-for="(call, i) in calls">
                                <v-card
                                    v-if="type_call === call.type_call"
                                    min-width="100%"
                                    max-width="100%"
                                    class="text-center"
                                    outlined
                                    tile
                                >
                                    <line-chart :chartData="call" :options="optionsChartLine"></line-chart>
                                </v-card>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers2"
                            :items="listAdminItems"
                            :loading="loading"
                            :locale="lang"
                            :loading-text="$t('loading_please_wait')"
                            :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                            :item-class="rowClass2"
                            class="elevation-0"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                <thead>
                                <tr class="rowHeadClass Table2">
                                    <th v-for="h in headers" :key="h.value" class="table_header">
                                        <span>{{ h.text }}</span>
                                    </th>
                                </tr>
                                </thead>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                            <span class="font_weight_600">
                                                {{ item.name }}
                                            </span>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </ValidationObserver>
        </template>
        <template v-slot:side v-if="false">
            <v-icon color="primary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
            <v-list class="filter_left mt-5 background_color_transparent">
                <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <div class="filter_hr"></div>
            <v-list class="filter_left background_color_transparent">
                <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('additional') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
    </PageLayout>
</template>
<script>

import LineChart from '../plugins/charts/LineChart.js'
import BarChart from '../plugins/charts/BarChart.js'
import PieChart from '../plugins/charts/PieChart.js'
import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import PageLayout from "@/components/Leentech/PageLayout.vue";

export default {
    name: "AllDashboard",
    components: {
        BarChart,
        PieChart,
        LineChart,
        PageLayout,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            loading: false,
            tasks: [],
            optionsChartLine: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            //stepSize: 1,
                            beginAtZero: true,
                            callback: function (value) {
                                if (value % 1 === 0) {
                                    return value;
                                }
                            }
                        }
                    }]
                },
            },
            optionsChartBar: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            //stepSize: 1,
                            beginAtZero: true,
                            callback: function (value) {
                                if (value % 1 === 0) {
                                    return value;
                                }
                            }
                        }
                    }]
                },
            },
            system_filters: [
                {'title': 'Все контакты', 'count': '10'},
                {'title': 'Мои клиенты', 'count': '0'},
                {'title': 'Избранное', 'count': '7'},
            ],
            Additional: [
                {'title': 'База Казахстан', 'count': '10'},
                {'title': 'База Россия', 'count': '10'},
                {'title': 'База Узбекистан', 'count': '10'},
            ],
            group1: true,
            group2: true,
            filter_show: true,
            email_search: null,
            perPage: 5,
            dateFrom: null,
            dateMenuFrom: false,
            dateTo: null,
            dateMenuTo: false,
            calls: [],
            type_call: 'month',
            listAdminItems: [],
            headers2: [
                {
                    text: this.$t('manager'),
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t('quantity_short'),
                    align: "left",
                    sortable: true,
                    value: "count",
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },
    mounted() {
        this.dateFrom = this.$moment().startOf('month').format('YYYY-MM-DD');
        this.dateTo = this.$moment().format('YYYY-MM-DD');

        this.getCountOrders()
        this.getCountChart()
        this.getCountListAdmin()
    },
    methods: {
        search() {
            this.getCountOrders()
            this.getCountChart()
            this.getCountListAdmin()
        },
        rowClass2() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table2' : 'rowMobiClass';
        },
        getCountOrders() {
            let params = {};

            if (this.dateFrom) {
                params.dateFrom = this.dateFrom;
            }
            if (this.dateTo) {
                params.dateTo = this.dateTo;
            }
            this.$http
                .get('admin/dashboard/tasks', {
                    params: params,
                })
                .then(res => {
                    this.tasks = res.body.data
                })
                .catch(err => {
                    this.tasks = [];
                    this.$toastr.error(this.$t('failed_to_get_list_charts'))
                })
        },
        getCountChart() {
            let params = {};

            if (this.dateFrom) {
                params.dateFrom = this.dateFrom;
            }
            if (this.dateTo) {
                params.dateTo = this.dateTo;
            }
            this.$http
                .get('admin/reports/call_chart', {
                    params: params,
                })
                .then(res => {
                    this.calls = res.body.data
                })
                .catch(err => {
                    this.calls = [];
                    this.$toastr.error(this.$t('failed_to_get_list_charts'))
                })
        },
        async getCountListAdmin() {
            this.loading = true

            let params = {};


            if (this.dateFrom) {
                params.dateFrom = this.dateFrom;
            }
            if (this.dateTo) {
                params.dateTo = this.dateTo;
            }


            await this.$http
                .get("admin/reports/call_admin", {
                    params: params,
                })
                .then(res => {
                    this.listAdminItems = res.body.data
                })
                .catch(err => {
                    this.listAdminItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_administrators'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
